<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          入库单详情
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-descriptions title="" >
          <el-descriptions-item label="入库来源">{{toStr({1:'创建入库', 2:'采购入库', 3:'客户退款'},info.inventory_type)}}</el-descriptions-item>
          <el-descriptions-item label="关联单号">{{info.order_no}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{info.create_time}}</el-descriptions-item>
          <el-descriptions-item label="入库单号">{{info.inventory_no}}</el-descriptions-item>
          <el-descriptions-item label="商品项数">{{info.goods_num}}</el-descriptions-item>
          <el-descriptions-item label="单据金额">{{info.amount}}元</el-descriptions-item>
          <el-descriptions-item label="制单人">{{info.account_name}}</el-descriptions-item>
          <el-descriptions-item label="状态">{{ toStr({1:'待审核', 2:'同意', 3:'拒绝'}, info.status)}}</el-descriptions-item>
          <el-descriptions-item label="审核时间" v-if="info.status != 1">{{info.check_time}}</el-descriptions-item>
          <el-descriptions-item label="拒绝原因" v-if="info.status == 3">{{info.reason}}</el-descriptions-item>
       </el-descriptions>

        <div class="itemTime">入库商品</div>
        <el-input
          style="width:240px;margin-bottom:10px;"
          v-model.trim="form.data.goods_name"
          placeholder="请输入商品名称"
          size="small"
        >
          <el-button slot="append" icon="el-icon-search" @click="getTable"></el-button>
        </el-input>

        <el-table
          :data="tableData"
          style="width: 100%"
          border>
          <el-table-column type="index" label="ID" fixed></el-table-column>
          <el-table-column
            prop="good_img"
            label="商品名称"
            width="180px"
            fixed>
            <template slot-scope="{row}">
              <div style="display: flex;align-items: center;">
                <el-image
                  v-if="!!row.goods_image"
                  style="width: 40px; height: 40px"
                  :src="row.goods_image"
                  fit="cover"
                  :preview-src-list="[row.goods_image]"></el-image>
                <el-image
                  v-else
                  style="width: 40px; height: 30px"
                  :src="require('@/assets/img/logo-icon.jpeg')"
                  fit="cover"
                  :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
                <div style="margin-left:10px;">
                  <div>{{row.good_name}}</div>
                  <div>{{row.code}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="cate_name"
            label="分类">
          </el-table-column>
          <el-table-column
            prop="supplier_name"
            label="供应商/采购员"
            width="120px">
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="spec_name"
            label="规格">
          </el-table-column>
          <el-table-column
            prop="basic_scale"
            label="转换基础单位"
            width="120px">
            <template slot-scope="{row}">
              <!-- 有设置基础单位转换的则展示 -->
              <span v-if="row.basic_scale > 0">1{{row.unit_name}} = {{row.basic_scale}}{{row.base_unit}} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="入库数量">
            <template slot-scope="{row}">
              <span>{{row.quantity}}{{row.unit_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="均价">
          </el-table-column>
          <el-table-column
            prop="total_amount"
            label="小计/元">
          </el-table-column>
          <el-table-column
            prop="stash_name"
            label="所在仓库"
            width="120px">
          </el-table-column>
          <el-table-column
            prop="give_date"
            label="生产日期">
          </el-table-column>
          <!-- 剩余保质期、剩余数量，仅针对已通过的才有 -->
          <template v-if="info.status == 2">
            <el-table-column
              prop="quality_days"
              label="剩余保质期">
            <template slot-scope="{row}">
              <span v-if="!!row.quality_days">{{row.quality_days}}天</span>
              <span v-else>0</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="surplus_quantity"
              label="剩余数量">
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="footerWrap">
        <!-- status 1:'待收货', 2:'已关闭', 3:'部分收货', 4:'全部收货'  -->
        <el-button v-if="this.info.status != 2" type="primary" plain size="medium" @click="plexportData">导出</el-button>
      </div>
    </div>
   
  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'purchaseReceive',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        info: {},
        tableData: [],
        table: {
          total:0,
        },
        
        loading: false,
        form: {
          loading: false,
          data: {
            goods_name: ''
          },
        },
        currRow: {},
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row
        this.getTable();
      },
      getTable() {
        let _params = {
          id: this.currRow.id,
          goods_name: this.form.data.goods_name,
          page: 1,
          count: 1000
        }
        this.$http.get('/admin/inventory_log/info', { params: _params}).then(res => {
          if(res.code == 1) {
            this.info = res.data.info;
            this.table.total = res.data.details.total;
            this.tableData = res.data.details; // 商品信息
          }
        })
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 导出
      plexportData() {
        let url = '/admin/inventory_log/export'
        let str = ""
        let obj = {
          id: this.currRow.id
        }
        Object.keys(obj).forEach(item => {
            str += `&${item}=${obj[item]}`
        })
        console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.tableData = [];
      }

    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
</style>
