<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待审核</el-radio-button>
      <el-radio-button :label="2">已入库</el-radio-button>
      <el-radio-button :label="3">已拒绝</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="入库单号" prop="inventory_no">
          <el-input v-model.trim="table.params.inventory_no" placeholder="请输入" clearable @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="关联单号" prop="order_no">
          <el-input v-model.trim="table.params.order_no" placeholder="请输入" clearable @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="inventory_type">
          <el-select v-model="table.params.inventory_type" filterable placeholder="请输入" clearable @change="onSearch">
            <el-option label="创建入库" :value="1"></el-option>
            <el-option label="采购入库" :value="2"></el-option>
            <el-option label="客户退货" :value="3"></el-option>
            <el-option label="采购修改" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="创建日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建入库</el-button>
        
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="table.params.status == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 入库单号 -->
      <template v-slot:inventory_no="{row}">
        <el-link type="primary" @click="showDetail(row)">{{row.inventory_no}}</el-link>
      </template>
      <!-- 关联单号 -->
      <template v-slot:order_no="{row}">
        <el-link type="primary" @click="showDetail2(row)">{{row.order_no}}</el-link>
      </template>
      <template v-slot:inventory_type="{row}">
        <!-- cate_id 0:正常入库 1:转换入库 -->
        <span v-if="row.cate_id == 1">转换入库</span>
        <span v-else>{{toStr({1:'创建入库', 2:'采购入库', 3:'客户退款',4:'采购修改'},row.inventory_type)}}</span>
      </template>
      <template v-slot:stash_name="{row}">
        <span>{{row.stash_name}}（{{row.province_str}}{{row.city_str}}）</span>
      </template>
      <template v-slot:action="{row}">
        <!-- 登录账号为员工时没有审核操作 -->
        <template v-if="roleType != 3 && table.params.status == 1">
          <el-button type="text" @click="view(row)">同意</el-button>
          <el-button type="text" @click="handleReject(row)">拒绝</el-button>
        </template>
        <el-button type="text" @click="plexportData(row)">导出</el-button>
      </template>
    </VTable>

    <!-- 新增、编辑 --> 
    <edit ref="edit" @refresh="getTable"></edit>

    <!-- 详情 -->
    <detail ref="detail"></detail>

    <!-- 采购单详情 -->
    <PurchaseDetail ref="purchasedetail" ></PurchaseDetail>

    <!-- 订单详情 -->
    <OrderdDetail ref="orderdetail" ></OrderdDetail>


  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import PurchaseDetail from '@/views/purchase/list/components/Details.vue'
import OrderdDetail from '@/layout/OrderDetail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Detail,
    PurchaseDetail,
    OrderdDetail,
    Edit,
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['goodsTypeArr','areaArr'])
  },
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width: "80", hidden: false },
        { name: "inventory_no", label: "入库单号", width: "120", hidden: false },
        { name: "goods_num", label: "商品项数", width: "80", hidden: false },
        { name: "quantity", label: "入库数量", width:"100", hidden: false},
        { name: "inventory_type", label: "类型",width:"100", hidden: false},
        { name: "order_no", label: "关联单号",width: "190", hidden: false},
        { name: "account_name", label: "制单人",showTooltip: true, hidden: false},
        { name: "stash_name", label: "所在仓库",showTooltip: true,hidden: false},
        { name: "create_time", label: "创建时间",hidden: false},
        { name: "action", label: "操作",width: "140", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width: "80", hidden: false },
        { name: "inventory_no", label: "入库单号", width: "120", hidden: false },
        { name: "goods_num", label: "商品项数", width: "80", hidden: false },
        { name: "quantity", label: "入库数量", width:"100", hidden: false},
        { name: "inventory_type", label: "类型",width:"100", hidden: false},
        { name: "order_no", label: "关联单号", width: "190", hidden: false},
        { name: "account_name", label: "制单人", hidden: false},
        { name: "stash_name", label: "所在仓库",showTooltip: true, hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "check_time", label: "审核时间",hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          status: 1, //状态：1:待审核 2:同意 3:拒绝
          cate_id: '',
          order_no: '',
          inventory_no: '',
          inventory_type: '', //类型 1:创建入库 2:采购入库 3:客户退款,4:采购修改
          area: [],
          province: '',
          city: '',
          date: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _area = this.table.params.area;
      if(_area.length == 1) {
        _params.province = _area[0]
      } else if(_area.length == 2) {
        _params.province = _area[0]
        _params.city = _area[1]
      } else {
        _params.province = '';
        _params.city = '';
      }
      delete _params.area;
      this.table.loading = true;
      this.$http.get('/admin/inventory_log/list', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 查看入库单详情
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    showDetail2(row) {
      // inventory_type: 2:采购详情 3:订单详情
      if(row.inventory_type == 2) {
        var dom = this.$refs.purchasedetail;
        let obj = {
          id: row.order_id,
          showType: 9999
        }
        dom.toggle(true);
        dom.getDetail(obj);
        dom = null;
      } else if(row.inventory_type == 3) {
        var dom = this.$refs.orderdetail;
        dom.toggle(true);
        dom.getDetail(row.order_id);
        dom = null;
      }
    },
    // 同意
    view(row) {
      this.setConfirm('确认后现有库存数据将会更新对应商品库存，确认同意吗', {id: row.id, status:1}, '/admin/inventory_log/operation')
    },
    // 拒绝
    handleReject(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入（100字以内）",
        inputPattern:  /^.+$/,
        inputErrorMessage: '请输入拒绝原因',
        inputType:'textarea'
      }).then(({ value }) => {
        this.$http.post('/admin/inventory_log/operation', {id:row.id,status:2,reason:value}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.getTable();
          }
        })
      }).catch(() => {});
    },
    // 导出
    plexportData(row) {
      let url = '/admin/inventory_log/export'
      let str = ""
      let obj = {
        id: row.id
      }
      Object.keys(obj).forEach(item => {
          str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
  }
}
</script>
